var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-group',{staticClass:"pa-4",attrs:{"center-active":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.photos),function(item,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',[_c('v-card',{staticClass:"mx-4",class:active ? 'card-img-active' : '',attrs:{"color":"grey lighten-1","height":"100","width":"130"},on:{"click":toggle}},[(item.typefile == 'image')?[_c('v-img',{staticClass:"card-img",class:active ? 'img-active' : '',attrs:{"height":"100%","width":"100%","src":item.url}})]:[_c('div',{staticClass:"aplicationfile text-uppercase"},[_c('p',{staticClass:"typefile"},[_vm._v(_vm._s(item.type))])])]],2),_c('v-card',{staticClass:"my-1 mx-4",attrs:{"color":"white","max-height":"30","max-width":"130","height":"40","width":"130"}},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.preetyName(item.name)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"warning"},on:{"click":function($event){return _vm.downloadUrl(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"warning","size":"20"},domProps:{"textContent":_vm._s('mdi-download')}})],1)]}}],null,true)},[_c('span',[_vm._v("Download Item")])])],1)])],1)]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }