
















































































import { notificationError } from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import axios, { AxiosError } from "axios";
import store from "@/store";
export default {
  props: {
    Items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      model: "",
      loading: false,
      photos: [],
    };
  },
  destroyed() {
    (this as any).photos = [];
  },
  mounted() {
    (this as any).photos = [];
    if ((this as any).Items != []) {
      (this as any).getPhotos((this as any).Items);
    }
  },
  watch: {
    Items(val: any) {
      (this as any).photos = [];
      if (val != []) {
        (this as any).getPhotos(val);
      }
    },
  },
  methods: {
    preetyName(name: string) {
      let splitname = name.split(".")[0];
      const ext = name.split(".")[1];
      if (splitname.length > 6) {
        splitname = splitname.slice(0, 6) + "...";
      }
      return splitname + "." + ext;
    },
    getPhotos(values: any) {
      const token = store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      (this as any).loading = true;
      values.forEach((elm: any) => {
        const url = `${apiBaseUrl}/patient/downloadFile/${elm.uuid}`;
        axios({
          method: "get",
          url: url,
          responseType: "blob",
          headers: auth,
        }).then((response) => {
          const typeFile = response.data.type.split("/")[0];
          const type =
            typeFile == "image" || typeFile == "video" ? typeFile : "document";
          const buffer = new Blob([response.data]);
          const file_path = URL.createObjectURL(buffer);
          (this as any).photos.push({
            typefile: typeFile,
            type: type,
            url: file_path,
            name: elm.minioName,
            uuid: elm.uuid,
          });
        });
      });
      (this as any).loading = false;
    },
    downloadUrl({ uuid, name, url }: any) {
      const a = document.createElement("A") as HTMLLinkElement;
      a.href = url;
      a.setAttribute("style", "display: none");
      a.setAttribute("target", "_blank");
      (a as any).download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
