

















/**
 * Button to upload documents from checklist
 */
import { notifyError } from "@/components/Notification";
import Vue from "vue";

export default Vue.extend({
  name: "btn-upload-document",
  props: {
    fileProps: {
      type: Object,
      default: () => ({}),
    },
    value: [Blob, File, Array],
  },
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  methods: {
    onChange(file: File) {
      if (file == null) return;

      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 6) {
        notifyError("Only files minor to <b>6 Mb</b> are allowed");
        this.$emit("input", new Blob());

        return;
      }

      this.$emit("input", file);
    },
  },
});
